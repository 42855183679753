import { Box, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { ArticleStatus, ArticleType, IAIArticle } from "../../types/models/article.types";
import { useGetUserSubscription } from "../../client/api/UserAPI";
import { UserArticlesContext } from "../../context/UserArticlesContext";
import { ArticleAPI, useGetArticle } from "../../client/api/ArticleAPI";
import { mutate } from "swr";
import CoreCard from "../core/CoreCard";
import { useGetAllUserProducts } from "../../client/api/ProductAPI";
import CoreSelect from "../core/CoreSelect";
import ButtonToggleGroup from "../ButtonToggleGroup";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import LockIcon from "@mui/icons-material/Lock";
import { CheckoutAPI } from "../../client/api/CheckoutAPI";
import PaymentRequiredModal from "../modal/PaymentRequiredModal";
import { SubscriptionFrequency } from "../../types/SubscriptionType";
import { useRouter } from "next/router";
import { IUserProduct, ProductSitemapScanStatus } from "../../types/models/user.types";
import CreateArticleForm from "./CreateArticleForm";
import CoreLoadingButton from "../core/CoreLoadingButton";
import CreateProductReviewForm from "./CreateProductReviewForm";
import CreateGuestPostForm from "./CreateGuestPostForm";
import CreateReviseArticleForm from "./CreateReviseArticleForm";
import { getArticleContentHref } from "../../const/Hrefs";
import { hasAllArticleRequiredParams } from "../../utils/ArticleUtils";

function areObjectsEqual(obj1, obj2) {
  if (!obj1 || !obj2) {
    return false;
  }
  // Check if both are the same reference
  if (obj1 === obj2) {
    return true;
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if number of properties is different
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check each property
  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  // If all checks passed, objects are considered equal
  return true;
}

const toFriendlyArticleType = (articleType: ArticleType): string => {
  switch (articleType) {
    case ArticleType.BLOG:
      return "Blog Post";
    case ArticleType.PRODUCT_REVIEW:
      return "Amazon Product Review";
    case ArticleType.GUEST_POST:
      return "Guest Post";
    case ArticleType.REVISE_ARTICLE:
      return "Rewrite Article";
    default:
      return articleType;
  }
};

const ArticleForm: React.FC<{
  type: ArticleType;
  article: Partial<IAIArticle>;
  onArticleChange: (article: Partial<IAIArticle>) => any;
  products: Array<{
    product: IUserProduct;
    status: ProductSitemapScanStatus;
  }>;
  isSignedIn: boolean;
  children: ReactNode;
}> = ({ type, article, onArticleChange, products, isSignedIn, children }) => {
  if (type === ArticleType.PRODUCT_REVIEW) {
    return (
      <CreateProductReviewForm
        article={article}
        onArticleChange={onArticleChange}
        isSignedIn={isSignedIn}
      >
        {children}
      </CreateProductReviewForm>
    );
  }

  if (type == ArticleType.GUEST_POST) {
    return (
      <CreateGuestPostForm
        article={article}
        onArticleChange={onArticleChange}
        isSignedIn={isSignedIn}
      >
        {children}
      </CreateGuestPostForm>
    );
  }

  if (type === ArticleType.REVISE_ARTICLE) {
    return (
      <CreateReviseArticleForm
        article={article}
        onArticleChange={onArticleChange}
        products={products}
        isSignedIn={isSignedIn}
      >
        {children}
      </CreateReviseArticleForm>
    );
  }

  return (
    <CreateArticleForm
      article={article}
      onArticleChange={onArticleChange}
      products={products}
      isSignedIn={isSignedIn}
    >
      {children}
    </CreateArticleForm>
  );
};

interface Props {
  articleId: string;
  userId: string;
  isSignedIn: boolean;
}

const TopicStepForm: React.FC<Props> = ({ articleId, userId, isSignedIn }) => {
  const router = useRouter();
  const { upsertArticle } = useContext(UserArticlesContext);
  const { data: articleData, mutate: onRefreshArticle } = useGetArticle(articleId);
  const { data: userSubscription } = useGetUserSubscription(userId);
  const { data: userProductsData } = useGetAllUserProducts(userId);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const hasSubscription = userSubscription?.has_subscription;
  const hasGpt4 = userSubscription?.gpt4_subscription;
  const planFrequency = userSubscription?.subscription_frequency;
  const products = userProductsData?.user_products;
  const initialArticle = articleData?.article;
  const [paymentRequired, setPaymentRequired] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [articleType, setArticleType] = useState<ArticleType>(ArticleType.BLOG);
  const [model, setModel] = useState<"gpt-3.5" | "gpt-4">("gpt-3.5");
  const [articleFields, setArticleFields] = useState<Partial<IAIArticle>>();

  // set defaults from query params
  useEffect(() => {
    if (articleFields?.topic || articleFields?.keywords) {
      return;
    }
    if (router?.query.topic || router?.query.keywords) {
      setArticleFields((prev) => {
        return {
          ...prev,
          topic: (router.query.topic as string) || prev?.topic,
          keywords: (router.query.keywords as string) || prev?.keywords,
        };
      });
    }
  }, [router?.query]);

  useEffect(() => {
    if (initialArticle) {
      setArticleFields(initialArticle);
      setModel(initialArticle?.model);
      setArticleType(initialArticle?.type);
    }
  }, [initialArticle]);

  const onRefresh = async (newArticle: IAIArticle) => {
    upsertArticle(newArticle);
    await onRefreshArticle(undefined, {
      optimisticData: { article: newArticle },
    });
    await mutate(`/api/articles/${articleId}/status`, undefined, {
      optimisticData: { status: ArticleStatus.NOT_STARTED },
    });
  };

  const handleArticleChange = (updatedArticleFields: Partial<IAIArticle>) => {
    setArticleFields((prev) => {
      return {
        ...prev,
        ...updatedArticleFields,
      };
    });
  };

  const handleArticleReset = () => {
    setArticleFields(undefined);
  };

  const handleArticleSubmit = async () => {
    setLoading(true);
    const article = {
      ...articleFields,
      id: articleId,
      user_id: userId,
      type: articleType,
      model,
    };

    try {
      if (!areObjectsEqual(article, initialArticle) || !articleId) {
        const response = await ArticleAPI.setArticleTopic(userId, article);
        articleId = response?.article?.id;
        await onRefresh(response?.article);
      }
      await router.replace(
        { pathname: router.pathname, query: { article_id: articleId } },
        undefined,
        {
          shallow: true,
          scroll: false,
        }
      );
      await router.push(getArticleContentHref(articleId));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleGpt4Upgrade = async () => {
    await CheckoutAPI.upgradeToGpt4(userId);
    window.location.reload();
  };

  const handleGptModel = (value: "gpt-3.5" | "gpt-4") => {
    if (value === "gpt-4" && !hasGpt4) {
      setPaymentRequired(true);
      return;
    }
    setModel(value);
  };

  const handleArticleType = (value: ArticleType) => {
    setArticleType(value);
    if (value === initialArticle?.type) {
      setArticleFields(initialArticle);
    } else {
      handleArticleReset();
    }
  };

  return (
    <>
      <CoreCard padding={isMobile ? 3 : 4}>
        <Grid container justifyContent="center">
          <Grid item lg={8.5} md={9} xs={12}>
            <Grid container justifyContent="center" rowSpacing={4}>
              <Grid item xs={12}>
                <Typography
                  textAlign="center"
                  color="primary"
                  variant="h4"
                  fontFamily="Open Sans Bold"
                  sx={{
                    textShadow: "1px 1px 3px rgba(0, 0, 0, 0.1)",
                    letterSpacing: "0.5px",
                  }}
                >
                  Create an Article
                </Typography>
                <Typography align="center">
                  Enter your topic and target keywords, and watch PredraftAI research and generate
                  it for you
                </Typography>
              </Grid>

              <Grid item xs={12} display="flex" justifyContent="center">
                <Box sx={{ width: "80%" }}>
                  <CoreSelect
                    label="Article Type"
                    value={articleType}
                    onChange={(value) => {
                      handleArticleType(value as ArticleType);
                    }}
                    selectItems={Object.values(ArticleType).map((item: ArticleType) => ({
                      label: toFriendlyArticleType(item),
                      value: item,
                    }))}
                  />
                </Box>
              </Grid>

              {hasSubscription && (
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Box width="auto">
                    <ButtonToggleGroup
                      value={model}
                      handleValueChange={(value) => handleGptModel(value as "gpt-3.5" | "gpt-4")}
                      buttonToggleItems={[
                        {
                          label: "GPT-4o mini",
                          value: "gpt-3.5",
                          color: "secondary",
                          startIcon: <ElectricBoltIcon />,
                        },
                        {
                          label: "GPT-4o",
                          value: "gpt-4",
                          color: "primary",
                          startIcon: <AutoAwesomeIcon />,
                          endIcon: !hasGpt4 && <LockIcon sx={{ fontSize: "inherit" }} />,
                        },
                      ]}
                    />
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <ArticleForm
                  type={articleType}
                  article={articleFields}
                  onArticleChange={handleArticleChange}
                  products={products}
                  isSignedIn={isSignedIn}
                >
                  <Stack spacing={2} direction="row" justifyContent="center">
                    <CoreLoadingButton onClick={handleArticleReset} text="Reset" variant="text" />
                    <CoreLoadingButton
                      onClick={handleArticleSubmit}
                      text="✨ Write Article ✨"
                      loading={loading}
                      disabled={!hasAllArticleRequiredParams(articleFields, articleType)}
                    />
                  </Stack>
                </ArticleForm>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CoreCard>
      <PaymentRequiredModal
        open={paymentRequired}
        onCheckout={handleGpt4Upgrade}
        title="Unlock GPT-4o"
        buttonText="Upgrade Now"
        onClose={() => {
          setPaymentRequired(false);
        }}
      >
        <Typography>
          Elevate your articles with GPT-4 for just $
          {planFrequency === SubscriptionFrequency.YEAR ? "120/yr" : "10/mo"}. Get the most advanced
          AI for richer, more compelling content.
        </Typography>
      </PaymentRequiredModal>
    </>
  );
};

export default TopicStepForm;
