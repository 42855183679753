import { Box, Divider, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import SocialProof from "@/components/marketing/SocialProof";
import FeaturesGrid from "@/components/marketing/FeaturesGrid";
import MainLayout from "../components/MainLayout";
import { NextPageWithLayout } from "./_app";
import { useRouter } from "next/router";
import Head from "next/head";
import TopicStepForm from "../components/topic/TopicStepForm";
import CoreCard from "../components/core/CoreCard";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Image from "next/image";
import ComparisonChart from "../components/ComparisonChart";
import CreateArticle from "../../public/create_article.png";
import SuggestionArticles from "../../public/suggestions.png";
import ExternalLinksImage from "../../public/external_links.png";
import ReviseArticleImage from "../../public/revise_article.png";
import FBPixel from "@/components/marketing/FBPixel";
import { useAuth } from "../utils/client/useAuth";
import GradientTypography from "../components/core/GradientTypography";
import SampleArticles from "@/components/marketing/SampleArticles";
import FeatureShowcase2 from "@/components/marketing/FeatureShowcase2";
import CoreLoadingButton from "@/components/core/CoreLoadingButton";
import { getSignUpHref } from "@/const/Hrefs";

const BatteriesIncludedMarketing = () => {
  const theme = useTheme();
  return (
    <>
      <FBPixel eventName={"PageView"} />
      <CoreCard backgroundColor={theme.palette.primary.main} padding={8}>
        <Grid container spacing={6} maxWidth="xl" sx={{ pb: 5 }}>
          <Grid item xs={12}>
            <Typography
              sx={{ textAlign: "center" }}
              fontFamily="Open Sans Semi Bold"
              variant="h4"
              color={theme.palette.common.white}
            >
              Easy to Use, Batteries Included
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FeaturesGrid
              features={[
                {
                  title: "SEO Supercharged",
                  description:
                    "Trained on millions of articles, our AI doesn't just generate content, it weaves SEO magic into every line. No hocus pocus, just results!",
                },
                {
                  title: "Your Special Sauce",
                  description:
                    "Spice up the research outline with your secret ingredients. Because your unique insights are the caviar to your content canapés.",
                },
                {
                  title: "Plagiarism Free Zone",
                  description:
                    "Our integrated plagiarism checker keeps your content as unique as a unicorn in a field of horses. Authenticity? Check!",
                },
                {
                  title: "Faster than a Speeding Bullet",
                  description:
                    "No more drumming your fingers on the desk. With predraft.ai, you'll have your content quicker than you can say 'supercalifragilisticexpialidocious'.",
                },
                {
                  title: "Forever-Free Tier",
                  description:
                    "Our forever-free tier is just that - free forever. And no, we won't ask for your credit card. Pinky promise!",
                },
                {
                  title: "Tone It Up (or Down)",
                  description:
                    "You're the maestro here! Control the tone of your content to hit the right notes with your audience. Make it as funky or formal as you wish.",
                },
              ]}
            />
          </Grid>
        </Grid>
      </CoreCard>
    </>
  );
};

const FeaturesWithImages = () => {
  return (
    <Grid container spacing={[4, 4, 8, 8]}>
      {[
        {
          title: "AI Generated Articles",
          description: `Our focus guidance algorithm keeps you on track, ensuring your content remains
                relevant and targeted. Avoid straying from the core subject with our exclusive
                'exclude guidance'.`,
          img: {
            src: CreateArticle,
            alt: "create article",
          },
        },
        {
          title: "Suggested Articles",
          description: `Boost your content's uniqueness by integrating proprietary
           information, recent events, and personal insights. Stand out in search 
           engine results and engage your audience better.`,
          img: {
            src: SuggestionArticles,
            alt: "article suggestions",
          },
        },
        {
          title: "Auto Embedded External Links",
          description: `Let predraft.ai take care of writing and grammar while
           you focus on your ideas. Experience the ease of creating content
            without the hassle of editing or complaints.`,
          img: {
            src: ExternalLinksImage,
            alt: "external links",
          },
        },
        {
          title: "Web Scraping and Rewrite",
          description:
            "Automated tools to help you craft impressive SEO articles at a breakneck pace. Add external links to your generated article with a built in link builder.",
          img: {
            src: ReviseArticleImage,
            alt: "revise article",
          },
        },
      ].map((item) => (
        <Grid item xs={12} md={6} key={item.title}>
          <Stack spacing={[3, 3, 4, 4]}>
            <Box
              sx={{
                position: "relative",
                borderRadius: "12px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                overflow: "hidden",
              }}
              width="100%"
              height={300}
            >
              <Image
                src={item.img.src}
                alt={item.img.alt}
                fill={true}
                style={{ objectFit: "cover" }}
              />
            </Box>
            <Stack spacing={2}>
              <Typography variant="h5" fontFamily="Open Sans  Bold" color="primary">
                {item.title}
              </Typography>
              <Typography>{item.description}</Typography>
            </Stack>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};

const socialProofs = [
  {
    imageUrl: "/img/user1.webp",
    role: "A Content Marketing Manager",
    testimonial:
      "Predraft.ai is a great tool for content marketing managers. It helps me get publishable content in literally minutes without the overhead of managing contractors. Plus the outlines are useful as content briefs for my writers.",
  },
  {
    imageUrl: "/img/user2.webp",
    role: "A Freelance Writer",
    testimonial:
      "This is a gamechanger for freelance writers. I take on twice as many jobs at a time now and work a lot less. Way fewer back and forths because I make the inputs really good instead of typing as fast as I can to hit the wordcount. So much better.",
  },
  {
    imageUrl: "/img/user3.webp",
    role: "An SEO Consultant",
    testimonial:
      "I now sell writing services as part of my SEO strategy services because it's so easy to produce the content. In general, my strategy is more effective with more accurately written articles so this is a win-win.",
  },
];

const Home: NextPageWithLayout = () => {
  const { userId, isSignedIn } = useAuth();
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const articleId = router.query.article_id as string;

  function getStartedButton(text) {
    return (
      <CoreLoadingButton
        sx={{
          py: [1, 1, 1.5, 1.5],
          px: [2, 2, 4, 4],
          fontSize: "1.2em",
          transition: "transform 0.2s, boxShadow 0.2s, backgroundColor 0.2s",
          "&:hover": {
            transform: "scale(1.05)", // Slight zoom on hover
            boxShadow: "0px 0px 20px 10px rgba(255, 255, 255, 0.9)",
            backgroundColor: "primary.main",
            textShadow: "0px 0px 10px rgba(255, 255, 255, 0.9)",
          },
        }}
        text={text}
        onClick={() => router.push(getSignUpHref())}
      />
    );
  }

  return (
    <>
      <Head>
        <title>predraft.ai | 1-click SEO Researcher & Writer | Free Forever</title>
        <link rel="canonical" href="https://predraft.ai" key="canonical" />
      </Head>
      <Stack spacing={[3, 3, 8, 8]} sx={{ mt: 2 }}>
        <Stack maxWidth="xl" sx={{ textAlign: "center" }} spacing={3}>
          <Box>
            <Typography
              sx={{ textAlign: "center" }}
              fontFamily="Open Sans Semi Bold"
              variant={isMobile ? "h6" : "h4"}
              color="primary"
            >
              Higher quality content with less effort
            </Typography>
            <GradientTypography
              text="Researcher. Writer. Editor."
              variant="h2"
              display="inline"
              fontWeight="bold"
            />
          </Box>
          <Typography sx={{ textAlign: "center" }} variant={isMobile ? "h6" : "h5"} color="primary">
            Research and write your next SEO article with PredraftAI
          </Typography>
        </Stack>
        <TopicStepForm userId={userId} articleId={articleId} isSignedIn={isSignedIn} />
      </Stack>
      <Divider sx={{ my: 8 }} />
      <Box>
        <Typography
          align="center"
          variant="h4"
          fontFamily="Open Sans Bold"
          color="primary"
          sx={{ mb: 1 }}
        >
          Produce well-researched SEO content in minutes
        </Typography>
        <Typography
          align="center"
          variant="h5"
          fontFamily="Open Sans Semi Bold"
          color="primary"
          sx={{ mb: 4 }}
        >
          AI Purpose Built for SEO
        </Typography>
        <FeatureShowcase2 />
        <Box sx={{ textAlign: "center", mt: 8 }}>{getStartedButton("Create a Free Article")}</Box>
      </Box>
      <Divider sx={{ my: 8 }} />
      <Typography
        align="center"
        variant="h4"
        fontFamily="Open Sans Bold"
        color="primary"
        sx={{ mb: 1 }}
      >
        Better than GPT-4-mini
      </Typography>
      <Typography
        align="center"
        variant="h5"
        fontFamily="Open Sans Semi Bold"
        color="primary"
        sx={{ mb: 4 }}
      >
        PredraftAI is the most powerful AI tool on the market for SEO with GPT-4-mini and GPT-4o
      </Typography>
      <ComparisonChart />
      <Box sx={{ textAlign: "center", mt: 8 }}>{getStartedButton("Start for Free")}</Box>
      <Divider sx={{ my: 8 }} />
      <SampleArticles />
      <Divider sx={{ my: 8 }} />
      <Box>
        <Typography
          align="center"
          variant="h4"
          fontFamily="Open Sans Bold"
          color="primary"
          sx={{ mb: 4 }}
        >
          Research and Draft SEO Content Faster
        </Typography>
        <FeaturesWithImages />
        <Box sx={{ textAlign: "center", mt: 8 }}>{getStartedButton("Create Free Account")}</Box>
      </Box>
      <Divider sx={{ my: 8 }} />
      <Box>
        <Stack alignItems="center">
          <FavoriteBorderIcon fontSize="large" color="primary" sx={{ mb: 1 }} />
          <Typography
            align="center"
            variant="h4"
            fontFamily="Open Sans Bold"
            color="primary"
            sx={{ mb: 4 }}
          >
            Already Loved by Early Adopters
          </Typography>
        </Stack>
        <CoreCard padding={5}>
          <Stack spacing={4}>
            {socialProofs.map((socialProof) => (
              <SocialProof
                key={socialProof.testimonial}
                imageUrl={socialProof.imageUrl}
                role={socialProof.role}
                testimonial={socialProof.testimonial}
              />
            ))}
          </Stack>
        </CoreCard>
      </Box>
      <Divider sx={{ my: 8 }} />
      <BatteriesIncludedMarketing />
      <Box sx={{ textAlign: "center", mt: 8 }}>{getStartedButton("Start Now -- It's free!")}</Box>
    </>
  );
};

Home.getLayout = function getLayout(page: React.ReactElement) {
  return (
    <MainLayout showFooter maxWidth="lg">
      {page}
    </MainLayout>
  );
};

export default Home;
